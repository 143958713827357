import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Typography } from "@mui/material";
import { Form } from '@pdfme/ui';
import printJS from 'print-js';
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICertificate } from '../../entities/certificates';
import { ITemplate } from '../../entities/organization';
import { useTemplateFonts } from '../../lib/contexts/TemplateFonts';
import { formatDate, getDiplomaSerialNumber, removeAccents, undefinedSigleLabel } from '../../lib/helper';
import { AppDispatch, IState } from '../../lib/store';
import { updateCertificate } from '../../lib/store/projects/thunks';
import BeforePrintPreview from '../BeforePrintPreview';
import ConfirmationDialog from '../ConfirmationDialog';
import { enhanceMetadata, generatePDF, getTemplate } from './helper';

interface IProps {
    certificate: ICertificate;
    templateVersion: string;
}

const ConfirmBody: React.FC<{serialNumber: string}> = ({serialNumber}) => {
    return (
        <Box>
            <Typography>Diplôme imprimé avec succès ? veuillez confirmer si tel est le cas.</Typography>
            <span><strong>Numéro de serie: <span style={{color: serialNumber.includes(undefinedSigleLabel) ? 'red' : ''}}>{serialNumber}</span></strong> </span>
        </Box>
    )
}

const Component: React.FC<IProps> = ({certificate, templateVersion}) => {
    const dispatch = useDispatch<AppDispatch>();
    const templates = useSelector((state: IState) => state.organization.orgs[0].templates, shallowEqual)!;
    const [diplomaTemplate, setDiplomaTemplate] = useState<ITemplate>();
    const [blankTemplate, setBlankTemplate] = useState<ITemplate>();
    const [templateNotFound, setTemplateNotFound] = useState<boolean>(false);
    const [openAckDialog, setOpenAckDialog] = useState<boolean>(false);
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [metadata, setMetadata] = useState<Record<string, string>>({});
    const universityName = useSelector((state: IState) => state?.organization?.organization?.name);
    const [form, setForm] = useState<Form>();
    const templateFonts = useTemplateFonts();

    useEffect(() => {
        const dTpl = getTemplate(removeAccents(certificate!.metadata!['grade']))(templates, templateVersion) || getTemplate('Licence')(templates, templateVersion);
        const blankTpl = getTemplate('Blank')(templates, templateVersion);
        if (!dTpl || !dTpl.print) {
            setTemplateNotFound(true);
            return;
        }

        const bTpl = {
            ...dTpl?.print,
            template: {
                ...dTpl?.print.template,
                basePdf: blankTpl?.preview.template.basePdf!,
            },
        }
        
        setMetadata(enhanceMetadata({...certificate!.metadata}, universityName));
        setDiplomaTemplate(dTpl.print);
        setBlankTemplate(JSON.parse(JSON.stringify(bTpl)));
    }, [templateVersion]);
    
    const onPrintDialogClose = () => setOpenAckDialog(true);

    const print =  async () => {
        const file = await generatePDF({...blankTemplate?.template!}, form?.getInputs()!, templateFonts.fonts);
        const url = URL.createObjectURL(file);
        printJS({
            printable: url,
            type: 'pdf',
            onPrintDialogClose,
        });
    }

    const onPrinted = () => {
        const cert: ICertificate = Object.assign({}, certificate);
        cert.printed = true;
        cert.printDate = Date.now();
        cert.metadata = Object.assign({}, certificate.metadata);
        cert.metadata!['serial_number'] = metadata['serial_number'];
        delete cert.metadata['_id'];
        setLoading(true);
        dispatch(updateCertificate(cert)).unwrap()
            .then(() => toast.success('Impression terminée'))
            .catch(() => toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...'))
            .finally(() => {
                setLoading(false);
                setOpenAckDialog(false);
            });
    }

    const confirmPrint = () => {
        setOpenPreviewModal(false);
        print();
    }
    
    const openModal = () => {
        const unsets: string[] = [];
        diplomaTemplate?.template.columns?.map((c: string) => {
            if (!(metadata && metadata[c])) {
                unsets.push(c);
            }
        });

        if (unsets.length > 0) {
            toast.error(
                <>
                    <span>Ces champs suivant du diplome sont manquants ou mal formattés:</span>
                    <ul>
                        {unsets.map((c) => (
                            <li>{c}</li>
                        ))}
                    </ul>
                </>
            );
            return;
        }

        setOpenPreviewModal(true);
    }

    return (
        <>
            {templateNotFound && <span style={{color: 'red', fontSize: '15px'}}> template version: {templateVersion} introuvable </span>}
            {!templateNotFound && !certificate.printed && (
                <Button variant="outlined" color="success" onClick={openModal} >
                    <PrintIcon  />
                </Button>
            )}

            {/* {certificate.printed && (
                <span style={{paddingRight: '1rem', marginRight: '1rem', color: 'green'}}>
                    Diplôme imprimé le {formatDate(certificate.printDate! * 1000)}
                </span>
            )} */}

            {true && (
                <>
                    <ConfirmationDialog
                        open={openAckDialog}
                        close={() => setOpenAckDialog(false)}
                        actionLabel='Retour impression'
                        contentText={<ConfirmBody serialNumber={metadata['serial_number']} />}
                        succesLabel='Oui'
                        errorLabel='Non'
                        loading={loading}
                        action={onPrinted}
                        />
                
                    <BeforePrintPreview 
                        template={diplomaTemplate?.template!} 
                        inputs={[metadata]} 
                        open={openPreviewModal} 
                        close={() => setOpenPreviewModal(false)}
                        action={confirmPrint}
                        setForm={setForm}
                        />
                </>
            )}
        </>
    )
}

export default Component;